import React, { useState } from "react";
import { AbsoluteCenter, Box, Divider, Heading, Image, Text } from "@chakra-ui/react";
import { GiPlantRoots } from "react-icons/gi";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import tagin1 from "../../images/bgb.jpg";
import activity from "../../images/album.jfif";

const Activity = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Box w="100%" pos="relative" objectFit="cover" opacity="10" minH="100vh">
      <Box
        backgroundImage={` url(${tagin1})`}
        maxW="100%"
        mx="auto"
        minH="100vh"
        pos="relative"
      >
        <Box
          backgroundImage={` url(${tagin1})`}
          maxW="100%"
          mx="auto"
          minHeight="100vh"
        >
          <Navbar toggle={toggle} />
          <Sidebar isOpen={isOpen} toggle={toggle} />

          <div>
            <Heading textAlign="center" color="#fff" p={45}>
              OUR ACTIVITY
            </Heading>
            <Box position="relative" mb="px" >
              <Divider borderBottom="2px solid #fff" />
              <AbsoluteCenter
                bg="#150f0f"
                color="#fff"
                px="6"
                fontSize="2.7rem"
              >
                <GiPlantRoots />
              </AbsoluteCenter>
            </Box>

            <Box
            
            padding='50px 20px'
              w="100%"
            >
              <Text
                style={{
                  // fontFamily: "'Open Sans', sans-serif",
                  fontFamily: "'Open Sans', Arial, Tahoma, sans-serif",
                  fontWeight: "bold",
                }}
                color="#fff"
                fontSize="clamp(0.8rem , 1vw , 1.1rem)"
                mb="10px"
                textAlign="center"
                letterSpacing="1.3px"
                lineHeight="35px"
              >
                <span style={{ fontSize: "1.7rem", fontWeight: "bold" }}>
                  E
                </span>
                xperience the thrill of Bike Trekking amidst the picturesque mountains and waterfalls of Chefchaouen. <br />Traverse diverse terrains, from rugged trails to serene paths, and soak in the natural beauty around you.<br /> Learn about the local flora and fauna, and uncover the rich history of this enchanting region.<br /> 
                <span style={{ fontSize: "1.7rem", fontWeight: "bold" }}>
                  J
                </span>
                oin us for an exhilarating adventure at just 249.50 DH per person!<br />
                    
                {/* <span style={{ fontSize: "1.7rem", fontWeight: "bold" }}>
                  F
                </span>
                or just 249.50 DH per person, gear up for an unforgettable biking expedition and immerse yourself in the natural wonders of Chefchaouen! */}
              </Text>
             <Box w='100%' display='flex' alignItems='center' justifyContent='center' pt={10}>
             <Image src={activity} maxH='100vh'  w='100%' bgPos='center' objectFit='cover'/>
             </Box>
            </Box>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default Activity;
