import React, { useState } from "react";
import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import { FaMapMarkerAlt } from "react-icons/fa";

import { GiPlantRoots } from "react-icons/gi";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import tagin1 from "../../images/bgb.jpg";
const Location = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Box w="100%" pos="relative" objectFit="cover" opacity="10" minH="100vh">
      <Box
        backgroundImage={` url(${tagin1})`}
        maxW="100%"
        mx="auto"
        minH="100vh"
        pos="relative"
      >
        <Box
          backgroundImage={` url(${tagin1})`}
          maxW="100%"
          mx="auto"
          minHeight="100vh"
        >
          <Navbar toggle={toggle} />
          <Sidebar isOpen={isOpen} toggle={toggle} />

          <div>
            <Heading textAlign="center" color="#fff" p={45}>
              OUR LOCATION
            </Heading>
            <Box position="relative" mb="px">
              <Divider borderBottom="2px solid #fff" />
              <AbsoluteCenter
                bg="#150f0f"
                color="#fff"
                px="4"
                fontSize="2.7rem"
              >
                <GiPlantRoots />
              </AbsoluteCenter>
            </Box>

            <Box
              w="100%"
              zIndex="100"
              opacity="0.8"
              color="#fff"
              mt="00rem"
              mx="auto"
              p="3rem"
              borderRadius="md"
            >
              <Heading
                color="#fff"
                fontSize="clamp(1rem , 1.5vw, 1.5rem )"
                textAlign="center"
                mb="3rem"
              >
                If you have any questions, you can contact us at &nbsp;
                <a
                style={{textDecoration:'none', color:'#fff'}}
                  target="_blank"
                  href="https://api.whatsapp.com/send?phone=0658507211"
                >
                  +212 658 507 211
                </a>
              </Heading>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d203.84825484687735!2d-5.263577496018498!3d35.16729151060625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd0b27f39188e4f9%3A0xe3985315866b1b74!2sRestaurant%20Bab%20Ssour!5e0!3m2!1sen!2sma!4v1697561921807!5m2!1sen!2sma"
                width="100%"
                height="500"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Box>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default Location;
