import React from "react";
import { Bars, Nav, NavIcon, NavLink, Icon } from "./NavbarElements";
import { Flex, Image, Spacer } from "@chakra-ui/react";
import logo from '../../images/logoo.PNG'
import { Link } from "react-router-dom";
import "./navbar.css";
function Navbar({ toggle }) {
  return (
    <div>
      <Nav>
        <Link to="/">
          <img  class='img' src={logo}/>
        </Link>
        <Spacer />
        <NavLink to="/">Home</NavLink>
        <NavLink to="/about">About</NavLink>
        <NavLink to="/location">Location</NavLink>
        <NavLink to="/menu">Menu</NavLink>
        <NavLink to="/activity">Activity</NavLink>

        <a
          target="_blank"
          class="text"
          href="https://api.whatsapp.com/send?phone=0658507211"
        >
          Contact Us
        </a>

        <Spacer />
        <NavIcon onClick={toggle}>
          <p>Menu</p>
          <Bars />
        </NavIcon>
      </Nav>
    </div>
  );
}

export default Navbar;
