import React from 'react'
import {
    FeatureContainer , 
    FeatureButton
} from './FeatureElement'




function Feature() {
  return (
    <FeatureContainer>
           <h1>
            Tagine of the Day
           </h1>
           <p>
           Traditional Moroccan chicken tagine with olives and salted lemons.
           </p>
         
    </FeatureContainer>
  )
}

export default Feature
