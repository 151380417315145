import React from "react";
import { FiFacebook, FiTwitter, FiInstagram } from "react-icons/fi";
import { TbTruckDelivery, TbBrandTripadvisor } from "react-icons/tb";
import images from "../../assets/spoon.png";
import "./footer.css";
import { Flex, Spacer } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const Footer = () => (
  <div className="app__footer section__padding" id="login">
    {/* <Contact /> */}

    {/* <FooterOverlay /> */}
    {/* <Newsletter /> */}

    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contact Us</h1>
        <p className="p__opensans">
          Address: No 31 Rue Benjelloun, CHEFCHAOUEN, MOROCCO
        </p>
        <p className="p__opensans">
        Phone :&nbsp;&nbsp;
          <a
            class="what"
            style={{ textDecoration: "none", color: "#fff" }}
            href="tel:0660261128"
          >
            +212 660 261 128
          </a>
        </p>
        <p className="p__opensans">
          Delivery :&nbsp;&nbsp;
          <a
            class="what"
            style={{ textDecoration: "none", color: "#fff" }}
            href="tel:0660261128"
          >
            +212 660 261 128
          </a>
        </p>
        {/* <p className="p__opensans">
          Whatsapp :&nbsp;&nbsp;
          <a
            target="_blank"
            class="what"
            style={{ textDecoration: "none", color: "#fff" }}
            href="https://api.whatsapp.com/send?phone=0658507211"
          >
            +212 658 507 211
          </a>
          <Spacer />
        </p> */}

        <p className="p__opensans">
          Email:&nbsp;&nbsp;
          <a
            class="what"
            style={{ textDecoration: "none", color: "#fff" }}
            href="mailto:monebasa@yahoo.com"
          >
            monebasa@yahoo.com
          </a>
        </p>
        <p className="p__opensans">
          Email:&nbsp;&nbsp;
          <a
            class="what"
            style={{ textDecoration: "none", color: "#fff" }}
            href="mailto:contact@babsour.com"
          >
            contact@babsour.com
          </a>
        </p>
      </div>

      <div className="app__footer-links_logo">
        <h1 className="app__footer-headtext">RESTAURANT BAB SSOUR</h1>
        <p className="p__opensans">
          &quot;The purpose of human life is to serve, and to show compassion
          and the will to help others.&quot;
        </p>{" "}
        <p className="p__opensans">
          &quot;Albert Schweitzer.&quot;
        </p>
        <img src={images} className="spoon__img" style={{ marginTop: 15 }} />
        <div className="app__footer-links_icons">
          <a
            href="https://www.facebook.com/profile.php?id=100069596528629"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FiFacebook />
          </a>
          <a
            href="https://www.instagram.com/babssourbenyelou/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FiInstagram />
          </a>
          <a
            href="https://glovoapp.com/ma/fr/chefchaouen/beldi-bab-ssour/?content=menu-c.1107069489&section=desserts-moroccan-pastry-s.2613676403"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TbTruckDelivery />
          </a>
          <a
            href="https://www.tripadvisor.com/Restaurant_Review-g304013-d6879116-Reviews-Restaurant_Beldi_Bab_Ssour-Chefchaouen_Tanger_Tetouan_Al_Hoceima.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TbBrandTripadvisor />
          </a>
        </div>
      </div>

      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Working Hours</h1>
        <p className="p__opensans">Tuesday-Sunday:</p>
        <p className="p__opensans">12:00 AM - 10:30 PM</p>
        <p className="p__opensans">Monday : Closed</p>
      </div>
    </div>

    <div className="footer__copyright">
      <p className="p__opensans" style={{ textAlign: "center" }}>
        Copyright &copy; 2023 <em>Bab Ssour Restaurant </em> All rights
        Reserved.
      </p>
    </div>
  </div>
);

export default Footer;
