import React from "react";
import Products from "../Products/Products";
import HeroSection from "../HeroSection/HeroSection";
import Feature from "../Feature/Feature";
import { productData } from "../Products/data";
import Products2 from "../Products2/Products2";
import { productData2 } from "../Products2/data";

const Home = () => {
  return (
    <>
      <HeroSection />
      <Products heading="Our Top Tagines" data={productData} />
      <Feature />
      <Products2 heading="Our Top Food" data={productData2} />
    </>
  );
};

export default Home;
